<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.HistoryStages") }}</h1>
        <div id="about-text">
          {{ $t("message.AboutCompanyText1") }}
          {{ $t("message.AboutCompanyText2") }}
          {{ $t("message.AboutCompanyText3") }}
          {{ $t("message.AboutCompanyText4") }}
        </div>
        <v-card id="history-card">
          <div id="year">2016</div>
          <div id="history-card-text">
            {{ $t("message.HistoryCardText1") }}
            <br />
            {{ $t("message.HistoryCardText2") }}
          </div>
        </v-card>
        <v-card id="history-card">
          <div id="year">2017</div>
          <div id="history-card-text">
            {{ $t("message.HistoryCardText3") }}
          </div>
        </v-card>
        <v-card id="history-card">
          <div id="year">2018</div>
          <div id="history-card-text">
            {{ $t("message.HistoryCardText4") }}
            <v-spacer></v-spacer>
            {{ $t("message.HistoryCardText5") }}
          </div>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<style>
#history-card {
  display: flex;
  margin-top: 20px;
  
}
#year {
  padding: 10px;
  display: flex;
  align-items: center;
  width: 15%;
  font-size: 30px;
  font-weight: 500;
  color: #00569c;
  background-color: rgb(247, 247, 247);
}
</style>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
};
</script>
